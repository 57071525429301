import { LangContext } from 'contexts/LangContext';
import React, { useContext } from 'react';

export default function NotFound() {
  const { navigate } = useContext(LangContext);
  if (typeof window !== 'undefined') {
    navigate('/');
  }

  return <div style={{ height: '100vh' }} />;
}
